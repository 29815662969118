input, select{
    height: 40px;
    padding: 10px 20px;
    border: 1.5px solid #DDDDDD;
    width: 100%;
    border-radius: 5px;
    outline: none;
    transition: 0.2s all;
}
.ant-segmented{
    background-color: transparent !important;
}
.ant-segmented-item-selected{
    background-color: var(--secondary) !important;
}
.ant-checkbox:hover .ant-checkbox-inner{
    border-color: var(--secondary) !important;
}
.ant-checkbox-checked .ant-checkbox-inner{
    border-color: var(--secondary) !important;
    background-color: var(--secondary) !important;
    color: var(--secondary) !important;
}
textarea{
    padding: 10px 20px;
    border: 1.5px solid #DDDDDD;
    width: 100%;
    border-radius: 5px;
    outline: none;
    transition: 0.2s all;
}
.ant-table-row-selected{
    background-color: rgba(255, 99, 71, 0.292) !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after{
    background-color: var(--primary) !important;
}
.datepicker{
    width: 100%;
    height: 40px;
    border-radius: 5px !important;
    border: 1.5px solid #DDDDDD;
}
.ant-select-selection {
    border: none;
  }
  
.ant-select-selector {
    border: 1px solid #DDD;
    border-radius: 5px !important;
  }
.primary-contained-btn{
    background-color: var(--primary);
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}
.primary-outlined-btn{
    color: white;
    background-color: var(--primary);
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    border: 2px solid var(--primary);
    cursor: pointer;
}
.button-ligh-blue{
    background-color: #3A97ED;
    color: white;
    border-radius: 20px;
    padding: 6px 15px;
    border: none;
    cursor: pointer;
}
.ant-space-item button{
    color: white;
    background-color: var(--primary);
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    border: 2px solid var(--primary);
    cursor: pointer;
}
.ant-form-item{
    margin-bottom: 5px !important;
}
@media screen and (max-width: 1400px){
    .primary-outlined-btn{
        font-size: 12px;
        padding: 4px 12px;
    }
}

@media screen and (max-width: 1300px){
    .primary-outlined-btn{
        font-size: 10px;
        padding: 4px 10px;
    }
}
@media screen and (max-width: 700px){
    .primary-contained-btn{
        background-color: var(--primary);
        color: white;
        padding: 6px 14px;
        font-size: 12px;
        cursor: pointer;
        border: none;
        border-radius: 5px;
    }
    .primary-outlined-btn{
        color: white;
        background-color: var(--primary);
        border: none;
        padding: 6px 12px;
        font-size: 12px;
        border-radius: 5px;
        border: 2px solid var(--primary);
        cursor: pointer;
    }
    .ant-space-item button{
        padding: 2px 12px;
        font-size: 12px;
    }
}